import React from 'react';
import forzeroFour from "../../assets/forzerofour.jpg"

const FourZeroFour = () => {
    return (
        <div>
            <div>



                <img src={forzeroFour} alt=""/>
            </div>
        </div>
    );
};

export default FourZeroFour;